import * as React from "react";
import SeachJobsSection from "../components/JobsPage/SearchJobsSection";
import MainContentStructure from "../components/NavigationBar/MainContentStructure";

export default function SearchJobsForMobile(props) {
    return (
        <MainContentStructure>
            <SeachJobsSection />
        </MainContentStructure>
    )
};